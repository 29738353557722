<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/me">Me</router-link>
    <router-link to="/projects">Projects</router-link>
    <router-link to="/get-in-touch">Get in touch</router-link>
  </nav>
</template>

<script>
export default {
  name: 'PortfolioWebsiteHeaderComponent',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>

nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  width: 100vw;
  top: 0;
  background-color: #000;
  box-shadow: 4px 4px 4px 4px rgba(0,0,0,.5);
  a {
    color: white;
    text-decoration: none;
    display: block;
    height: 100%;
    margin: 0 16px;
    padding: 16px;
    border-radius: 5px;
    transition: .25s;
    &:hover {
      color: rgba(161, 78, 240, 1);
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 500px) {
  nav a {
    margin: 10px;
  }
}
@media screen and (max-width: 420px) {
  nav a {
    margin: 6px;
  }
}
@media screen and (max-width: 370px) {
  nav a {
    margin: 2px;
  }
}
</style>
