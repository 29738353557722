<template>
  <div id="app">
    <HeaderComponent />
    <router-view/>
    <BeforeFooterComponent />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
import BeforeFooterComponent from './components/BeforeFooterComponent.vue';

import HeaderComponent from './components/HeaderComponent.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    BeforeFooterComponent,
  },
};

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
html { scroll-behavior: smooth; }
* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  z-index: 10;
}

body {
  background-color: #111;
}

.highlight-font {
  color: rgba(161, 78, 240, 1);
}

.highlight-background {
  background-color: rgba(161, 78, 240, 1);
}
h1,h2,h3,h4,h5,h6 {
  letter-spacing: .25em;
  color: white;
  font-weight: 400;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.hidden {
  opacity: 0;
}

.hidden-right {
  transform: translate(50px, 0);
}

.hidden-left {
  transform: translate(-50px, 0);
}

.hidden-top {
  transform: translate(0, -50px);
}

.hidden-bottom {
  transform: translate(0, 50px);
}

.showElement {
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

p {
      color: white;
      font-size: large;
      line-height: 24px;
      letter-spacing: 3px;
      margin-top: 32px;
    }

.multi-column {
  display: flex;
  column-count: 3;
  column-fill: auto;
  max-height: 500px;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: wrap column;
}

.margin-top-big {
  margin-top: 160px
}

.margin-bottom {
  margin-bottom: 60px;
}

.focus-button {
  color:white;
  padding:10px;
  display: block;
  border-radius: 100px;
  background-color: rgba(161, 78, 240, 1);
  text-decoration: none;
  transition: .2s;
  width: fit-content;
  margin: 5vw;
  margin-right: auto;
  &:hover {
    text-decoration: underline;
    box-shadow: 0 0 4px 4px rgba(161, 78, 240, .3);
  }
}

.gallery {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 15% 45% 25%;
  grid-gap: 16px;
  align-items: start;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.place-center {
  margin-top: 160px;
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: row;
  > * {
    margin: 32px;
  }
}

ul {
  padding: 0;
  list-style-type: none;
}

.sub-list {
  padding-left: 30px;
}

li {
  color: gray;
  margin: 24px 0;
}

.content-container {
  width: 90%;
}
.content-section {
  max-width: 40vw;
  text-align: justify;
}

.content-section-right {
  text-align: right;
  align-items: center;
}

.content-section-center {
  text-align: center;
  align-items: center;
}

.content-section-img-container {
  height: auto;
  align-self: flex-end;
  margin: 16px 0;
  margin-left: calc(90vw - 40vw);
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .content-section-img-label {
    margin: 16px 0;
  }
}

.normal-link {
  color: rgba(161, 78, 240, 1);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: rgb(179, 133, 228);
  }
}

.stealth-link {
  transition: .25s;
  color: gray;
  text-decoration: none;
  &:hover {
    color: #111;
  }
}

.light-link {
  color: lightgray;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #111;
  }
}

.full-width {
  max-width: 100%;
}

@media screen and (max-width: 900px) {
  .content-section {
    max-width: 100%;
  }

  .content-section-img-container {
    margin-left: 30px;
  }
  .multi-column {
    display: flex;
    column-count: 2;
    column-fill: auto;
    max-height: 80vh;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: wrap column;
  }
}

@media screen and (max-height: 800px) {
  .scroll-down {
    bottom: 50px !important;
  }
}
@media screen and (max-width: 725px) {
  .gallery {
    grid-template-columns: 45% 45%;
  }
  .content-section {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  .gallery {
    grid-template-columns: 100%;
  }
  .multi-column {
    display: flex;
    column-count: 1;
    column-fill: auto;
    max-height: 1500px;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: wrap column;
  }
  nav {
    width: 100vw !important;
  }
}
i {
  font-family: 'FontAwesome', sans-serif;
  font-size: 4em;
  font-style: normal;
}

.project-using {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  .project-using-icons {
    display: flex;
    flex-direction: row;
    max-width: 70vw;
    flex-flow: wrap row;
    img {
      height: 3em;
      width: 3em;
      margin: 16px 16px 16px 0;
      transition: .25s;
      border-radius: 5px;
      display: inline-block;
      object-fit: contain;
      &:hover {
        background-color: rgba(179, 133, 228, .2);
        transform: translate(0, -10px);
      }
    }
    svg {
      display: inline-block;
      height: 3em;
      width: 3em;
      margin: 16px 16px 16px 0;
      transition: .25s;
      filter:
        invert(100%)
        sepia(0%)
        saturate(12%)
        hue-rotate(232deg)
        brightness(104%)
        contrast(104%);
      &:hover {
        filter:
          invert(42%)
          sepia(89%)
          saturate(4121%)
          hue-rotate(251deg)
          brightness(98%)
          contrast(91%);
          transform: translate(0, -10px);
      }
    }
    i {
      display: inline-block;
      margin: 16px 16px 16px 0;
      color: white;
      font-size: 3em;
      transition: .25s;
      width: fit-content;
      &:hover {
        color: rgba(161, 78, 240, 1);
        transform: translate(0, -10px);
      }
    }
  }
}

.content-section-heading {
  margin-top: 25vh;
  &:first-child {
    margin-top: 20px;
  }
}

.image-link img:hover {
  transition: .25s;
  display: inline-block;
  transform: translate(0, -10px);
}

a {
  text-decoration: none;
}
</style>
