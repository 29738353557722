<template>
  <div class="before-footer">
    <div class="legal-box">
      <router-link class="stealth-link" to="/legal">legal</router-link>
    </div>
    <h3>Find me on</h3>
    <div class="find-me-on-elements">
      <!-- eslint-disable-next-line -->
      <a href="https://github.com/Kreuterkeule">
        <i class="fa-brands fa-github"></i>
      </a>
      <!-- eslint-disable-next-line -->
      <router-link to="/notYetOn">
        <i class="fa-brands fa-twitter"></i>
      </router-link>
      <!-- eslint-disable-next-line -->
      <router-link to="/notYetOn">
        <i class="fa-brands fa-linkedin"></i>
      </router-link>
      <!-- eslint-disable-next-line -->
      <router-link to="/notYetOn">
        <i class="fa-brands fa-xing"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortfolioWebsiteBeforeFooterComponent',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="sass" scoped>
.before-footer
  width: 100%
  min-height: 120px
  background-color: #222
  margin-top: 10vh
  display: flex
  align-items: center
  flex-direction: column
  *
    margin: 16px
.find-me-on-elements
  margin: 0
  display: flex
  flex-direction: row
  *
    display: block
    color: white
    text-decoration: none
    transition: .25s
    margin: 0
    *
      padding: 16px
    &:hover
      color: rgba(179, 133, 228, 1)
.legal-box
  background-color: #333
  width: 100%
  height: 50px
  display: flex
  justify-content: center
  align-items: center
</style>
