export default {
  isLiteral: true,
  inserted: (el, binding) => {
    const f = () => {
      const rect = el.getBoundingClientRect();
      const inView = (
        rect.width > 0
        && rect.height > 0
        && rect.top >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
      if (inView) {
        el.classList.add(binding.value);
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
    f();
    setTimeout(f, 50);
    setTimeout(f, 500);
    setTimeout(f, 5000);
    // for longer to load taking elements like images on devices with a bad connection
  },
};
