import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/notYetOn',
    name: 'notYetOn',
    component: () => import('../views/NotYetOnView.vue'),
  },
  {
    path: '/using',
    name: 'using',
    component: () => import('../views/UsingView.vue'),
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('../views/MeView.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/ProjectsView.vue'),
  },
  {
    path: '/get-in-touch',
    name: 'get-in-touch',
    component: () => import('../views/GetInTouchView.vue'),
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../views/LegalView.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  /* eslint-disable-next-line */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { y: 0 };
  },
});

export default router;
