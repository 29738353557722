<template>
  <div class="home">
    <div class="header-image">
      <h1>
        ~$ whoami<br><span class="highlight-font">
          Moritz Siefke
        </span>
        <br>
        ~$
        <span class="cursor">
          &nbsp;
        </span>
      </h1>
      <!-- eslint-disable-next-line -->
      <h2 class="scroll-down"><a @click.prevent="scrollToStart()">Scroll Down<br>&#x2304;</a></h2>
    </div>
    <div id="what-i-do" class="content-container">
      <span id="what-i-do"></span>
      <h2
        id="first-exprience"
        class="hidden hidden-bottom content-section content-section-heading"
        v-infocus="'showElement'"
      >
        My first experience at
        <a href="https://visionthing.tech" class="normal-link">Vision Thing</a>
      </h2>
      <p class="hidden hidden-bottom content-section" v-infocus="'showElement'"
      >My first time working in a real company was at
      <a href="https://github.com/TheRedCraft" class="normal-link">Vision Thing</a>
      in February 2023.
      This is where I learned the most.
      I learnt way more than just how to produce code, I learned about <span class="highlight-font">
        Clean Code
      </span>, <span class="highlight-font">Unit testing</span> and how
      <span class="highlight-font">Marketing</span> works at a Company.
      <br>
      <br>
      Follow my thoughts on
      <a href="https://github.com/Kreuterkeule?tab=repositories&q=spring&type=&language=&sort=" class="normal-link">GitHub</a>
      </p>
      <div class="content-section-img-container">
        <a class="image-link" href="https://visionthing.tech">
        <h4 class="content-section-img-label hidden hidden-bottom" v-infocus="'showElement'"
        >Operating experience at <a href="https://visionthing.tech" class="normal-link">Vision Thing</a></h4>
        <img
        class="content-section content-section-img hidden hidden-bottom"
        src="../assets/operation-experience.png"
        alt=""
        loading="lazy"
        v-infocus="'showElement'">
        </a>
        <div class="project-using">
          <p class="hidden hidden-bottom content-section"
          v-infocus="'showElement'">featuring</p>
          <div class="hidden hidden-bottom content-section project-using-icons"
          v-infocus="'showElement'">
            <!-- eslint-disable-next-line -->
            <a href="https://java.com">
              <img src="../assets/java.png" alt="">
            </a>
            <!-- eslint-disable-next-line -->
            <a href="https://spring.io">
              <img src="../assets/spring-boot.png" alt="">
            </a>
            <!-- eslint-disable-next-line -->
            <a href="https://vuejs.org/">
              <img src="../assets/vuejs.png" alt="">
            </a>
            <!-- eslint-disable-next-line -->
            <a href="https://developer.paypal.com">
              <img src="../assets/paypal.png" alt="">
            </a>
            <!-- eslint-disable-next-line -->
            <a href="https://jenkins.io/">
              <img src="../assets/jenkins.png" alt="">
            </a>
            <!-- eslint-disable-next-line -->
            <a href="https://tomcat.apache.org/">
              <img src="https://img.icons8.com/color/256/tomcat.png" alt="">
            </a>
          </div>
        </div>
      </div>
      <h2
        id="first-exprience"
        class="hidden hidden-bottom content-section content-section-heading"
        v-infocus="'showElement'"
      >
        Me at
        <a href="https://www.netthelp.de" class="normal-link">Netthelp</a>
      </h2>
      <p class="hidden hidden-bottom content-section" v-infocus="'showElement'"
      >I'm a proud part of the german student company
      <a href="https://www.netthelp.de" class="normal-link">Netthelp</a>.
      at
      <a href="https://www.netthelp.de" class="normal-link">Netthelp</a>
      we help people with their Computers and host Websites for several Institutions.
      This is where I made a lot of experience working with clients.
      </p>
      <a class="image-link" href="https://www.netthelp.de">
        <div class="content-section-img-container">
          <h4 class="content-section-img-label hidden hidden-bottom" v-infocus="'showElement'"
          ><a href="https://www.netthelp.de" class="normal-link">Netthelp</a> (student company)</h4>
          <img
          class="content-section content-section-img hidden hidden-bottom"
          src="../assets/netthelp.png"
          alt=""
          v-infocus="'showElement'">
      </div>
      </a>
      <h2 class="hidden hidden-bottom content-section content-section-heading"
      v-infocus="'showElement'"
        >My Home Lab</h2>
      <p class="hidden hidden-bottom content-section" v-infocus="'showElement'"
      >My ever growing Project is my Home Lab.
      Im very interested in hardware.
      I have built my first PC when I was 12 and then it just kept coming.
      Read more about what I use
      <router-link to="/using" class="normal-link">here</router-link>.
      </p>
      <div class="content-section-img-container">
        <router-link class="image-link" to="/using">
        <h4 class="content-section-img-label hidden hidden-bottom" v-infocus="'showElement'"
        >My Home Lab</h4>
        <img
        class="content-section content-section-img hidden hidden-bottom"
        src="../assets/setup.png"
        alt=""
        v-infocus="'showElement'">
        </router-link>
        <div class="project-using">
          <p class="hidden hidden-bottom content-section"
          v-infocus="'showElement'">featuring</p>
          <div class="hidden hidden-bottom content-section project-using-icons"
          v-infocus="'showElement'">
            <!-- eslint-disable-next-line -->
            <a href="https://www.lttstore.com/products/screwdriver">
              <i class="fa fa-screwdriver"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <router-link class="focus-button hidden hidden-bottom" v-infocus="'showElement'" to="/projects"
    >All Projects</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import infocus from '../directives/infocus';

export default {
  name: 'HomeView',
  data() {
    return {
    };
  },
  methods: {
    scrollToStart() {
      document.getElementById('what-i-do').scrollIntoView();
    },
  },
  directives: {
    infocus,
  },
  components: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>

.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-image {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/moritz1.jpg);
    background-color: rgba(0,0,0,0.7);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .scroll-down {
      position: absolute;
      bottom: 100px;
      a {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: white;
        text-decoration: none;
        transition: .15s;
        &:hover {
          color: rgba(255,255,255,.5);
        }
      }
    }
    h1 {
      margin: 24px;
      color: white;
      font-weight: 400;
      letter-spacing: .25em;
      font-size: 4em;
      .typing {
        animation: none;
      }
      .cursor {
        display: inline-block;
        margin-left: 3px;
        width: 30px;
        animation: cursorBlink 1s infinite;
      }
    }

  }
}

#what-i-do {
  margin-top: 48px;
  padding: 24px;
  padding-top: 100px;
  min-height: 200px;
  height: auto;
}

@keyframes cursorBlink {
  49% { background-color: white}
  50% { background-color: transparent}
  99% { background-color: transparent}
}

@media screen and (max-width: 470px) {
  h1 {
    font-size: 3em !important;
  }
}

</style>
