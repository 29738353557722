<template>
  <div class="footer">
    <p class="copyright">Copyright 2023 Moritz Siefke</p>
  </div>
</template>

<script>
export default {
  name: 'PortfolioWebsiteFooterComponent',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="sass" scoped>

.footer
  width: 100%
  min-height: 400px
  background-color: #000
  display: flex
  align-items: center
  justify-content: center
  p
    text-align: center
    color: white
    font-size: 24px
    letter-spacing: 5px

</style>
